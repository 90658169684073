<template>
	<div class="hx-order-three">

		<div align="left" class="left">
			<img src="../../assets/ysmimg/ysmorderoneimg.png" width="90px" height="110px"
				style="vertical-align: middle;">
			<div align="left">
				<h3>牙位详图</h3>
				<span style="font-size: 0.9375rem;line-height: 1.875rem;">牙位图上传与下载,此项不是必填项。</span>
				<div style="font-size: 0.9375rem;line-height: 1.875rem;">说明:下载牙位图打印后手绘需求,完成后拍照上传</div>
				<!-- <div  style="font-size: 0.9375rem;line-height: 1.875rem;">您可以在<a  href="./mx.pdf" target="_blank" 
						style="color: #0D8EA4;font-weight: bold;text-decoration: underline;">此处</a>找到有关图片的示例。</div> -->
				<!-- <el-form :model="ruleForm" ref="ruleForm">
				<el-form-item prop="chengxiangDate" label="成像日期">
						<el-date-picker readonly="readonly" type="date" placeholder="成像日期" v-model="ruleForm.scDate"
							style="width: 100%;" value-format="yyyy-MM-dd" format="yyyy-MM-dd"></el-date-picker>
					</el-form-item>
				</el-form> -->
				<!-- <div style="font-size: 0.9375rem;line-height: 1.875rem;"><span style="font-weight: bolder;">注意</span>：图像需近30天内</div> -->
				<div style="font-size: 0.9375rem;line-height: 1.875rem;"><span style="font-weight: bolder;">有效格式</span>：.jpg、.jpeg、.dcm(DICOM)、.bmp、.tif、.tiff</div>
				<div style="font-size: 0.9375rem;line-height: 1.875rem;"><span style="font-weight: bolder;">最小文件大小</span>：100KB</div>
			</div>

		</div>



	<div class="right">
		<!-- 牙齿图片 -->
		<div style="margin-left: 20px;">
		  <span style="display:block;margin-bottom: 2px;"><a style="font-weight: bold;text-decoration: underline;font-size: 0.875rem;cursor: pointer;" @click="aaa()" id="uuu">下载图片</a></span>
		  <!-- <img src="../../assets/hximg/ya.jpg" style="width: 22.5rem; height: 12rem;" /> -->
		  <img :src="ys" style="width: 22.5rem; height: 12rem;" />
		</div>
		<div class="cut-picture">
			<div style="margin-left: 20px;">牙位图上传</div>
		      <!-- 上传组件 -->
		         <el-upload style="margin-left: 20px;margin-bottom: 50px !important;"  class="upload-demo" :show-file-list="false" action="https://blht.techlion.com.cn/client/order/addHXPlanPic" :data="{oid:'7896ea52eb2a4e0b9ec411bf01ea5f97'}" drag :multiple="false" :auto-upload="false" :on-change="selectChange">
		           <div v-show="ycVisiable" style="width: 100%; min-height: 30px;line-height: 30px;position: absolute;top: 0px;background-color: #0D8EA4;color: white;word-break: break-all; " >{{ycMess}}</div>
				   <!-- TODO:我自己替换掉的图片，你可以自己使用原来的逻辑 -->
				   <template v-if="list.length">
		             <img :src="list[0].url" alt="eee" style="width:100%;height:100%;">
		           </template>
		           <template v-else>
		             <i class="el-icon-upload"></i>
		             <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
		             <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
		           </template>
		         </el-upload>
		         <!-- 裁剪弹窗 -->
		         <el-dialog title="裁剪" :visible.sync="showCorp" width="600px" @close="onClose">
		           <div style="height:580px;padding:20px;">
		             <vueCropper ref="cropper" :img="option.img" :outputSize="option.outputSize" :outputType="option.outputType" :full="option.full" :canMove="option.canMove" :canMoveBox="option.canMoveBox" :original="option.original" :autoCrop="option.autoCrop" :fixed="option.fixed" :fixedNumber="option.fixedNumber" :centerBox="option.centerBox" :infoTrue="option.infoTrue" :fixedBox="option.fixedBox" :autoCropWidth="option.autoCropWidth" :autoCropHeight="option.autoCropHeight">
		             </vueCropper>
		           </div>
		           <div style="display:flex;justify-content:center;">
		             <el-button type="primary" size="default" @click="showCorp = false">取消</el-button>
		             <el-button type="warning" size="default" @click="onCrop">确定</el-button>
		           </div>
		         </el-dialog>
		  </div>
		
		<!-- 请上传牙齿图片-->
		<div v-if="false" style="margin-left: 20px;">牙位图上传</div>
		<el-upload v-if="false" style="margin-left: 20px;margin-bottom: 50px !important;" class="upload-demo1" drag action='' multiple 
			:http-request="changeFile1">
			<img v-show="cwVisiable" class="cw" :src="cwPicUrl" style="width: 100%; height: 100%;" /><!-- word-break: keep-all; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; -->
			<div v-show="ycVisiable" style="width: 100%; min-height: 30px;line-height: 30px;position: absolute;top: 0px;background-color: #0D8EA4;color: white;word-break: break-all; " >{{ycMess}}</div>
			<i class="el-icon-upload"></i>
			<div class="el-upload__text">将文件拖到此处或<em>点击上传</em><br/>如修改请重新点击上传</div>
			<div class="el-upload__tip" slot="tip"></div>
		</el-upload>
		<div style="text-align: right;">
			<el-button @click="back" class="back" type="button">返回</el-button>
			<el-button @click="nextStepKS" class="nextStep" type="button">下一步</el-button>
		</div>
		
	</div>
	
	</div>
</template>

<script>
	// 裁剪组件
	import { VueCropper } from "vue-cropper";
	export default {
		data: function() {
			return {
				ycVisiable:false,
				ycMess:'',
				ys:'',
				downloadName:'yachi.jpg',
				ruleForm: {
					quanjingDate: '',
					ceweiDate: '',
					oid: '0',
					scDate:'',
				},
				cwPicUrl:'',
				cwVisiable:false,
				show: false,
				
				showCorp: false,
							// 裁剪配置
							      option: {
							        img: "", //
							        info: true, // 裁剪框大小信息
							        outputSize: 1, //裁剪生成图片的质量
							        outputTpe: "jpg", // 格式
							        canSale: false, //是否允许滚轮缩放
							        autoCrop: true, //是否默认生成截图框
							        autoCropWidth: 340, //默认生成截图框宽度
							        autoCropHeight: 540, //默认生成截图框高度
							        fixed: false, //是否开启截图框宽高固定比例
							        fixedNumber: [2, 1], //截图框的宽高比例
							        full: false, //是否输出原图比例的截图
							        fixedBox: true, //固定截图框大小
							      },
							      // 图片列表
							      list: [],
							      // 裁剪图片临时存放
							      tempFile: {},
			}
		},
		
		components:{
			VueCropper
		},
		methods: {
			 /**
			     * @descrpition 选择文件触发事件
			     */
			    selectChange: function (f) {
			      this.tempFile = f;
			      this.option.img = URL.createObjectURL(f.raw);
			      this.showCorp = true;
			    },
			    /**
			     * @description 裁剪图片方法（确定按钮）触发
			     */
			    onCrop: function () {
			      this.$refs.cropper.getCropBlob((blob) => {
			        this.upload(blob);
			      });
			    },
			    /**
			     * @description 裁剪框关闭之后清除图片信息
			     */
			    onClose: function () {
			      this.tempFile = {};
			    },
			    /**
			     * @description 上传图片方法
			     */
			    upload: function (raw) {
			      const form = new FormData();
			      form.append("file", raw, this.tempFile.name);
			      // TODO:替换
			      //form.append("oid", "7896ea52eb2a4e0b9ec411bf01ea5f97");
				  form.append("oid", this.ruleForm.oid);
			      const loading = this.$loading({
			        text: "上传中",
			      });
			      // TODO:我使用的是默认的没有封装过的axios 你自己需要重新使用你自己的网络请求替换掉
			      this.$axios.post("/client/order/addHXPlanPic", form)
			        .then((res) => {
			          console.log(res)
			          loading.close();
			          this.showCorp = false
			          this.list = [{ url: res.data.data.picUrl }];
					  //
					  let fName=res.data.data.picUrl.substring(27,res.data.data.picUrl.length);
					  console.log(fName)
					  //https://tp.techlion.com.cn/404-4b61.png
					   let index1=fName.lastIndexOf("-")
					   let index2=fName.lastIndexOf('.')
					  let a1=fName.substring(index1,index2)
					  fName=fName.replace(a1,"")
					  											 this.ycMess="资源上传解析完毕："+fName;
					  											 var houZhui=res.data.data.picUrl.substring(res.data.data.picUrl.lastIndexOf('.')+1);
					  											 console.log(houZhui);
					  											 if(houZhui=='jpg'||houZhui=='JPG'||houZhui=='PNG'||houZhui=='png'||houZhui=='jpeg'||houZhui=='JPEG'){
					  												 this.cwVisiable=true;
					  												 this.cwPicUrl=res.data.data.picUrl;
					  											 }else{
					  												  this.cwPicUrl="";
					  												   this.cwVisiable=false;
					  											 }
					  this.ycVisiable=true;
			        });
			    },
			////////////////////////////////////////
			//牙齿图片上传
			changeFile1(file) {
				console.log(file.file);
				let formData = new FormData()
				formData.append('file', file.file) // 传文件
				formData.append('oid', this.ruleForm.oid)
				let self = this
				let config = {
					headers: {
						"Content-Type": "multipart/form-data"
					}
				};
				/* this.$axios.post('/client/order/addHXPlanPic', formData, config)
					.then(res => {
						
						if (res.data.code == 200) {
							// this.$alert("上传成功","提示");
							this.cwVisiable=true;
							//上传成功,图片回显
							// console.log(res.data.data.picUrl);
							this.cwPicUrl=res.data.data.picUrl;
						}
					}).catch(err => {
						this.$alert("请联系管理员","提示");
					}); */
					this.ycVisiable=false;
					
					this.$axios({
					                    method:'post',
					                    headers: {
					                        "Content-Type": "multipart/form-data"
					                    },
					                    url:'/client/order/addHXPlanPic',//自定义上传url
					                    data:formData,
					                    onUploadProgress: progressEvent => {
											let pros1=document.getElementsByClassName("el-progress el-progress--line");
											console.log(pros1.length);
											for(let i=0;i<pros1.length;i++){
												console.log(111);
												pros1[i].style.width="90% !important";
											}
											 if (progressEvent.lengthComputable) {
											                        //属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
											                        //如果lengthComputable为false，就获取不到progressEvent.total和progressEvent.loaded
											                     let percent=(progressEvent.loaded / progressEvent.total * 100) | 0
											                     file.onProgress({percent:percent});//调用uploader的进度回调
																 if(percent==100){
																 		this.ycMess="文件已上传至服务器,资源解析中......";
																 		this.ycVisiable=true;
																 }
											                    }
					                         
					                    }
					                }).then((res)=>{
										console.log(res.data);
					                    if(res.data.code==200){
											 let pros=document.getElementsByClassName("el-progress el-progress--line");
											for(let i=0;i<pros.length;i++){
												pros[i].style.display="none";
											} 
											let fName=res.data.data.picUrl.substring(27,res.data.data.picUrl.length);
											let index1=fName.lastIndexOf("-")
											 let index2=fName.lastIndexOf('.')
											let a1=fName.substring(index1,index2)
											fName=fName.replace(a1,"")
											 this.ycMess="资源上传解析完毕："+fName;
											 var houZhui=res.data.data.picUrl.substring(res.data.data.picUrl.lastIndexOf('.')+1);
											 console.log(houZhui);
											 if(houZhui=='jpg'||houZhui=='JPG'||houZhui=='PNG'||houZhui=='png'||houZhui=='jpeg'||houZhui=='JPEG'){
												 this.cwVisiable=true;
												 this.cwPicUrl=res.data.data.picUrl;
											 }else{
												  this.cwPicUrl="";
												   this.cwVisiable=false;
											 }
					                       this.ycVisiable=true;
											this.$message({
												message:"上传成功",
												type:"success",
												duration:900
											});
											
					                    }else{
					                        this.$message.error('上传失败');
					                    }
					                }).catch((err)=>{
					                    this.$message.error(err);
					                })
			},
			//下一步
			nextStepKS() {
				this.$store.commit("setHxStepIndex", 4);
				this.$router.push("/hxmain/hxorder/hxorderfour");
			},
			//返回
			back(){
				this.$store.commit("setHxStepIndex",2);
				this.$router.push("/hxmain/hxorder/hxordertwo");
			},
			
			/* 关于下载图片的 */
			downloadByBlob (url) {
				this.downloadName=url.substring(27,url.length);
				console.log(this.downloadName);
			  let image = new Image()  //创建图⽚对象
			  image.setAttribute('crossOrigin', 'anonymous') //设置允许跨域
			  image.src = url //赋值src
			  image.onload = () => {
			    //等待图⽚加载完成创建canvas
			    let canvas = document.createElement('canvas')
			    //将图⽚绘制到canvas画布上
			    canvas.width = image.width
			    canvas.height = image.height
			    let ctx = canvas.getContext('2d')
			    ctx.drawImage(image, 0, 0, image.width, image.height)
			    //此处同样是利⽤canvas⾃带API将画布数据导出为bolb流格式
			    canvas.toBlob((blob) => {
			      let url = URL.createObjectURL(blob)
			      const a = document.createElement("a");
			      a.download = this.downloadName;
			      // 直接将canvas导出的bolb:URL 格式赋值为a标签的href属性同样进⾏点击触发下载
			      a.href = url;
			      //document.body.appendChild(a);
				  a.click();
				    //document.removeChild(a);
				        // 释放URL对象
				        URL.revokeObjectURL(url)
			    })
				}
				},
				aaa(){
					this.downloadByBlob(this.ys);
				}
				
				
				
		},
		mounted: function() {
		
			this.$store.commit("setHxStepIndex",3);
			//设置默认地址
			/* sessionStorage.setItem("location", "/hxmain/hxorder/hxordertwo") */
			//获取当前添加的订单oid
			//获取oid
			 let oid=sessionStorage.getItem("oid");
			 if(oid==null||oid=="null"||oid==""||oid==undefined){
			 	this.$alert("请填写患者信息","提示");
			 	this.$router.push("/hxmain/hxorder/hxorderone");
			 }else{
			 	this.ruleForm.oid=oid;
			 }
			/* document.getElementsByClassName("fun1")[0].setAttribute("class","fun1 sub-menue1 visible")
			 document.getElementsByClassName("fun")[0].setAttribute("class","fun sub-menue")
			 document.getElementsByClassName("fun2")[0].setAttribute("class","fun2 sub-menue2");
			 document.getElementsByClassName("fun3")[0].setAttribute("class","fun3 sub-menue3"); */
			//设置返回路径
			/* this.$store.commit("setBackLocation","/hxmain/hxorder/hxordertwo"); */
			document.getElementsByClassName("saveb")[0].setAttribute("class","saveb show");
			document.getElementsByClassName("fh")[0].setAttribute("class","fh show");
			document.getElementsByClassName("print")[0].setAttribute("class","print die");
		},
		created: function() {
			
			//如果是编辑订单要获取oid的值
			let oid = sessionStorage.getItem("oid");
			if (oid != "undefined" && oid != '' && oid != null) {
				this.ruleForm.oid = oid;
				this.$axios.get("/client/order/getOrderInfo", {
					params: {
						oid: this.ruleForm.oid
					}
				}).then(res => {
					// let cwDate = res.data.data.resource.cwDate;
					let cwPicUrl = res.data.data.plan.planPdf;
					console.log( res.data.data.plan.planPdf);
					if(res.data.data.plan.planPdf!=null){
						this.list = [{ url: res.data.data.plan.planPdf }];
					}
					
					//回显侧位图片
					if (cwPicUrl != null) {
						let fName=cwPicUrl.substring(27,cwPicUrl.length);
						let index1=fName.lastIndexOf("-")
						 let index2=fName.lastIndexOf('.')
						let a1=fName.substring(index1,index2)
						fName=fName.replace(a1,"")
						var houZhui=cwPicUrl.substring(cwPicUrl.lastIndexOf('.')+1);
						if(houZhui=='jpg'||houZhui=='JPG'||houZhui=='PNG'||houZhui=='png'||houZhui=='jpeg'||houZhui=='JPEG'){
																	this.cwVisiable=true;
																	this.cwPicUrl=cwPicUrl;
																	this.ycVisiable=true;
																	this.ycMess=fName;
						}else{
							this.ycVisiable=true;
							this.ycMess=fName;
						}
						
					}
					//this.ruleForm.scDate=res.data.data.createdTime;
				}).catch(err => {
					this.$alert("系统出现异常,请联系管理员!");
				});
			}else{
				// this.$alert('请填写患者信息', '提示', {
				// 	confirmButtonText: '确定',
				// 	callback: action => {
				// 		this.$router.push("/hxmain/hxorder/hxorderone");
				// 	}
				// });
			}
			//动态获取牙图片
			this.$axios.get("/getImgUrl", {
				params: {
					type: "hxPdf"
				}
			}).then(res => {
				this.ys=res.data.data.url
					
			}).catch(err => {
				this.$alert("系统出现异常,请联系管理员!");
			});
			
		}
	}
</script>

<style scoped="scoped">
	.hx-order-three {
		width: 80%;
		margin: 0px auto;
		display: flex;
		margin-top: 3.125rem;
		/* padding-bottom: 5.125rem; */
		padding-bottom: 10px;
		justify-content: space-around;
	}

	h3 {
		font-family: 'themixitalic';
		font-weight: 400;
		line-height: 1.1;
		color: #0D8EA4;
		margin-bottom: 20px;
		font-size: 24px;
	}

	.left {
		
	/* 	display: flex;
		margin-right: 12.5rem;
		width: 31.25rem;
		position: relative;
		left: 12.5rem; */
		display: flex;
		width: 30rem;
		position: relative;
		justify-content: flex-start;
	}
	.right{
		 width: 560px;
		 text-align: left;
	}
	.hx-order-three .right .el-upload-dragger{
		width: 17rem;
	}

	/* 按钮 */
	.nextStep {
		background-color: #0D8EA4 !important;
		border-color: #0D8EA4 !important;
		color: #fff !important;
		min-width: 7.5rem;
	}

	.back {
		min-width: 7.5rem;
		background-color: transparent;
		border-color: #0D8EA4 !important;
		color: #0D8EA4 !important;
	}
</style>
